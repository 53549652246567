import React, { useCallback } from 'react'
import { tracker } from './tracker'
import type { ComlinkPushParams } from '@blue-agency/comlink/dist/src/app'
import type { DetailedHTMLProps, PropsWithRef, HTMLAttributes } from 'react'

type WithComlinkPushOnClickProps<T> = T & {
  comlinkPushParams: Partial<ComlinkPushParams>
}

export type PropsWithRefOf<
  T extends HTMLElement,
  K extends HTMLAttributes<T>,
> = PropsWithRef<DetailedHTMLProps<K, T>>

export const withComlinkPushOnClick = <
  T extends HTMLElement,
  K extends HTMLAttributes<T>,
>(
  Component: React.FC<PropsWithRefOf<T, K>>
) => {
  const ComponentWithComlinkPushOnClick: React.FC<
    WithComlinkPushOnClickProps<PropsWithRefOf<T, K>>
  > = ({ onClick, comlinkPushParams, ...props }) => {
    const handleClick = useCallback(
      (event: React.MouseEvent<T, MouseEvent>) => {
        tracker.click(event, comlinkPushParams)
        onClick && onClick(event)
      },
      [comlinkPushParams, onClick]
    )

    return (
      <Component
        {...(props as unknown as PropsWithRefOf<T, K>)}
        onClick={handleClick}
      />
    )
  }

  return ComponentWithComlinkPushOnClick
}
