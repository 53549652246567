import { Box, Divider, Flex } from '@chakra-ui/react'
import Head from 'next/head'
import { Footer } from '@/components/common/Footer'
import { Header } from '@/components/common/Header'
import type { PageMetaProps } from './Layout'

type Props = {
  children: React.ReactNode
  pageMeta?: PageMetaProps
}

export const StaticLayout = ({ children, pageMeta }: Props) => {
  return (
    <Flex flexDir='column' h='100vh'>
      <Head>
        <title>{pageMeta?.title && `${pageMeta.title}｜`}トレキャン</title>
        {pageMeta?.description && (
          <meta name='description' content={pageMeta.description} />
        )}
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta
          property='og:title'
          content={
            pageMeta?.title ? `${pageMeta.title}｜トレキャン` : 'トレキャン'
          }
        />
        {pageMeta?.description && (
          <meta name='og:description' content={pageMeta.description} />
        )}
        {/* TODO: 必要に応じてmeta情報を増やす */}
      </Head>
      {/* NOTE: 静的なレイアウトなのでログインの有無を判断せずLogout状態と同じにする */}
      <Header isUserLogin={false} />
      <Divider />
      <Box flexGrow='1'>{children}</Box>
      <Footer showMenuWithLogin={false} />
    </Flex>
  )
}
