import {
  TeamBelongingRole,
  SceneStatus,
  SubmissionResult as SubmissionResultEnum,
  UserStatus as UserStatusEnum,
  SubmissionVideoStatus as SubmissionVideoStatusEnum,
  TranscriptionStatus as TranscriptionStatusEnum,
  SubmissionAnalysisVersion as SubmissionAnalysisVersionEnum,
  ModelTalkScriptAnalysisMarkerType,
  SubmissionTranscriptionAnalysisMarkerType,
  LoginMethod as ProtoLoginMethod,
  ScenePracticeMode as ProtoScenePracticeMode,
} from '@blue-agency/proton/web/v2/orbiter/enum_pb'
import { assertNever } from '@/utils/assertNever'
import type { LoginMethodType } from '@/services/authService/auth'
import type { ScenePracticeMode } from '@/services/mySceneService/myScene'
import type { Status } from '@/services/sceneService/scene'
import type {
  SubmissionResult,
  SubmissionVideoTranscodingStatus,
  SubmissionVideoTranscriptionStatus,
  SubmissionAnalysisVersion,
} from '@/services/submissionService/submission'
import type {
  ModelTalkScriptMarkerType,
  SubmissionTranscriptionMarkerType,
} from '@/services/submissionService/submissionAnalysis'
import type { Role, UserStatus } from '@/services/userService/user'

export const fromProtoPracticeMode = (
  protoPracticeMode: ProtoScenePracticeMode
): ScenePracticeMode => {
  switch (protoPracticeMode) {
    case ProtoScenePracticeMode.SCENE_PRACTICE_MODE_RANDOM:
      return 'random'
    case ProtoScenePracticeMode.SCENE_PRACTICE_MODE_SCENARIO:
      return 'scenario'
    case ProtoScenePracticeMode.SCENE_PRACTICE_MODE_UNSPECIFIED:
      throw new Error('scene practice mode is unspecified')
    default:
      assertNever(protoPracticeMode)
  }
}

export const fromProtoLoginMethodType = (
  protoLoginMethod: ProtoLoginMethod
): LoginMethodType => {
  switch (protoLoginMethod) {
    case ProtoLoginMethod.LOGIN_METHOD_ID_PASSWORD:
      return 'password'
    case ProtoLoginMethod.LOGIN_METHOD_SSO_GOOGLE:
      return 'google'
    case ProtoLoginMethod.LOGIN_METHOD_SSO_MICROSOFT:
      return 'microsoft'
    case ProtoLoginMethod.LOGIN_METHOD_UNSPECIFIED:
      throw new Error('login method is unspecified')
    default:
      assertNever(protoLoginMethod)
  }
}

export const fromProtoTeamBelongingRole = (
  protoRole: TeamBelongingRole
): Role => {
  switch (protoRole) {
    case TeamBelongingRole.TEAM_BELONGING_ROLE_LEADER:
      return 'leader'
    case TeamBelongingRole.TEAM_BELONGING_ROLE_MEMBER:
      return 'member'
    case TeamBelongingRole.TEAM_BELONGING_ROLE_UNSPECIFIED:
      throw new Error('team belonging role reached unspecified')
    default:
      assertNever(protoRole)
  }
}

export const fromProtoSceneStatus = (protoStatus: SceneStatus): Status => {
  switch (protoStatus) {
    case SceneStatus.SCENE_STATUS_PUBLISHED:
      return 'published'
    case SceneStatus.SCENE_STATUS_DRAFT:
      return 'draft'
    case SceneStatus.SCENE_STATUS_UNSPECIFIED:
      throw new Error('scene status is unspecified')
    default:
      assertNever(protoStatus)
  }
}

export const fromProtoSubmissionResult = (
  protoResult: SubmissionResultEnum
): SubmissionResult => {
  switch (protoResult) {
    case SubmissionResultEnum.SUBMISSION_RESULT_ANALYZING:
      return 'analyzing'
    case SubmissionResultEnum.SUBMISSION_RESULT_SUCCESS:
      return 'success'
    case SubmissionResultEnum.SUBMISSION_RESULT_DISQUALIFIED:
      return 'disqualified'
    case SubmissionResultEnum.SUBMISSION_RESULT_ANALYSIS_FAILURE:
      return 'analysisFailure'
    case SubmissionResultEnum.SUBMISSION_RESULT_UNSPECIFIED:
      throw new Error('submission result is unspecified')
    default:
      assertNever(protoResult)
  }
}

export const fromProtoSubmissionVideoStatus = (
  protoStatus: SubmissionVideoStatusEnum
): SubmissionVideoTranscodingStatus => {
  switch (protoStatus) {
    case SubmissionVideoStatusEnum.SUBMISSION_VIDEO_STATUS_TRANSCODING:
      return 'transcoding'
    case SubmissionVideoStatusEnum.SUBMISSION_VIDEO_STATUS_SUCCESS:
      return 'success'
    case SubmissionVideoStatusEnum.SUBMISSION_VIDEO_STATUS_FAILURE:
      return 'failure'
    case SubmissionVideoStatusEnum.SUBMISSION_VIDEO_STATUS_UNSPECIFIED:
      throw new Error('submission video status is unspecified')
    default:
      assertNever(protoStatus)
  }
}

export const fromProtoTranscriptionStatus = (
  protoStatus: TranscriptionStatusEnum
): SubmissionVideoTranscriptionStatus => {
  switch (protoStatus) {
    case TranscriptionStatusEnum.TRANSCRIPTION_STATUS_PROCESSING:
      return 'processing'
    case TranscriptionStatusEnum.TRANSCRIPTION_STATUS_SUCCESS:
      return 'success'
    case TranscriptionStatusEnum.TRANSCRIPTION_STATUS_FAILURE:
      return 'failure'
    case TranscriptionStatusEnum.TRANSCRIPTION_STATUS_UNSPECIFIED:
      throw new Error('transcription status is unspecified')
    default:
      assertNever(protoStatus)
  }
}

export const fromProtoUserStatus = (
  protoStatus: UserStatusEnum
): UserStatus => {
  switch (protoStatus) {
    case UserStatusEnum.USER_STATUS_INVITED:
      return 'invited'
    case UserStatusEnum.USER_STATUS_ACTIVATED:
      return 'activated'
    case UserStatusEnum.USER_STATUS_SUSPENDED:
      return 'suspended'
    case UserStatusEnum.USER_STATUS_UNSPECIFIED:
      throw new Error('user status is unspecified')
    default:
      assertNever(protoStatus)
  }
}

export const fromProtoModelTalkScriptMarkerType = (
  protoType: ModelTalkScriptAnalysisMarkerType
): ModelTalkScriptMarkerType => {
  switch (protoType) {
    case ModelTalkScriptAnalysisMarkerType.MODEL_TALK_SCRIPT_ANALYSIS_MARKER_TYPE_MATCHED_WORD:
      return 'matchedWord'
    case ModelTalkScriptAnalysisMarkerType.MODEL_TALK_SCRIPT_ANALYSIS_MARKER_TYPE_MISSING_REQUIRED_WORD:
      return 'missingRequiredWord'
    case ModelTalkScriptAnalysisMarkerType.MODEL_TALK_SCRIPT_ANALYSIS_MARKER_TYPE_MISSING_WORD:
      return 'missingWord'
    case ModelTalkScriptAnalysisMarkerType.MODEL_TALK_SCRIPT_ANALYSIS_MARKER_TYPE_REQUIRED_WORD:
      return 'requiredWord'
    case ModelTalkScriptAnalysisMarkerType.MODEL_TALK_SCRIPT_ANALYSIS_MARKER_TYPE_UNSPECIFIED:
      throw new Error('model talk script analysis marker type is unspecified')
    default:
      assertNever(protoType)
  }
}

export const fromProtoSubmissionTranscriptionMarkerType = (
  protoType: SubmissionTranscriptionAnalysisMarkerType
): SubmissionTranscriptionMarkerType => {
  switch (protoType) {
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_MATCHED_WORD:
      return 'matchedWord'
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_HARD_TO_HEAR:
      return 'hardToHear'
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_REQUIRED_WORD:
      return 'requiredWord'
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_NG_WORD:
      return 'ngWord'
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_LONG:
      return 'long'
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_FILLER:
      return 'filler'
    case SubmissionTranscriptionAnalysisMarkerType.SUBMISSION_TRANSCRIPTION_ANALYSIS_MARKER_TYPE_UNSPECIFIED:
      throw new Error(
        'submission transcription analysis marker type is unspecified'
      )
    default:
      assertNever(protoType)
  }
}

export const fromProtoSubmissionAnalysisVersion = (
  protoVersion: SubmissionAnalysisVersionEnum
): SubmissionAnalysisVersion => {
  switch (protoVersion) {
    case SubmissionAnalysisVersionEnum.SUBMISSION_ANALYSIS_VERSION_V1:
      return 'v1'
    case SubmissionAnalysisVersionEnum.SUBMISSION_ANALYSIS_VERSION_V2:
      return 'v2'
    case SubmissionAnalysisVersionEnum.SUBMISSION_ANALYSIS_VERSION_UNSPECIFIED:
      throw new Error('submission analysis version is unspecified')
    default:
      assertNever(protoVersion)
  }
}
