import { useEffect, useState } from 'react'
import { getCurrentSession } from '@/lib/auth'
import type { CurrentSession } from '@/lib/auth'

export const useSession = () => {
  const [session, setSession] = useState<CurrentSession | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getCurrentSession()
      .then((s) => {
        setSession(s)
        setIsLoading(false)
      })
      .catch((e) => console.error(e))
  }, [])

  return { session, isLoading }
}
