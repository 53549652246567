// https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
const awsConfig = {
  region: 'ap-northeast-1',

  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USERPOOLID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USERPOOLWEBCLIENTID,

  // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn:
      process.env.NEXT_PUBLIC_COGNITO_OAUTH_HOSTNAME + '/auth/login',
    redirectSignOut:
      process.env.NEXT_PUBLIC_COGNITO_OAUTH_HOSTNAME + '/auth/logout',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
}

export default awsConfig
