import { useMediaQuery } from '@chakra-ui/react'
import { theme } from '@/lib/theme'

export const useIsPcSize = () => {
  /**
   * NOTE: ブレイクポイントの数値についてはlib/theme.tsの数値を参照すること
   */
  const MINIMUM_PC_WIDTH: string = theme.breakpoints.sm

  const [isPcSize] = useMediaQuery(`(min-width: ${MINIMUM_PC_WIDTH})`)

  return isPcSize
}
