import type { ComlinkPushParams } from '@blue-agency/comlink/dist/src/app'

type Callbacks = {
  click: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    comlinkPushParams: Partial<ComlinkPushParams>
  ) => void
}

class Tracker {
  private callbacks: Callbacks = {
    click: () => {
      // 初期化されるまではなにもしない
    },
  }

  public init(callbacks: Callbacks) {
    this.callbacks = callbacks
  }

  public click(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    comlinkPushParams: Partial<ComlinkPushParams>
  ) {
    this.callbacks.click(event, comlinkPushParams)
  }
}

export const tracker = new Tracker()
export const initTracking = (callbacks: Callbacks) => {
  tracker.init(callbacks)
}
