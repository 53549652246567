export const ACTIONS = {
  APPEND: {
    STEP: 'append_step',
    STEP_GROUP: 'append_step_group',
  },

  ATTACH: {
    SCENE_TO_TEAM_SCENE: 'attach_scene_to_team_scene',
    TEAMS_TO_TEAM_GROUP: 'attach_teams_to_team_group',
    USERS_TO_TEAM: 'attach_users_to_team',
  },

  CANCEL: {
    USER_INVITATION: 'cancel_user_invitation',
  },

  CHANGE: {
    PASSWORD: 'change_password',
    PREVIEW_VIDEO_PLAYING_STATUS: 'change_preview_video_playing_status',
    PREVIEW_VIDEO_VOLUME: 'change_preview_video_volume',
  },

  CLOSE: {
    ATTACH_SCENE_TO_TEAM_SCENE_FORM_MODAL:
      'close_attach_scene_to_team_scene_form_modal',
    ATTACH_TEAMS_TO_TEAM_GROUP_MODAL: 'close_attach_teams_to_team_group_modal',
    ATTACH_USERS_TO_TEAM_MODAL: 'close_attach_users_to_team_modal',
    CANCEL_USER_INVITATION_MODAL: 'close_cancel_user_invitation',
    CREATE_TEAM_GROUP_MODAL: 'close_create_team_group_modal',
    CREATE_TEAM_MODAL: 'close_create_team_modal',
    DETACH_TEAM_FROM_TEAM_GROUP_MODAL:
      'close_detach_team_from_team_group_modal',
    DETACH_USER_FROM_TEAM_MODAL: 'close_detach_user_from_team_modal',
    DETACH_USER_MODAL: 'close_detach_user_modal',
    DELETE_SCENE_MODAL: 'close_delete_scene_modal',
    DELETE_STEP_MODAL: 'close_delete_step_modal',
    DELETE_TEAM_MODAL: 'close_delete_team_modal',
    INVITE_USERS_MODAL: 'close_invite_users_modal',
    PUBLISH_SCENE_MODAL: 'close_publish_scene_modal',
    RESEND_INVITATION_MAIL_MODAL: 'close_resend_invitation_mail_modal',
    RESUME_USER_MODAL: 'close_resume_user_modal',
    SCENE_DETAIL: 'close_scene_detail',
    DELETE_STEP_GROUP_MODAL: 'close_delete_step_group_modal',
    DETACH_SCENE_MODAL: 'close_detach_scene_modal',
    SWITCH_TEAM_MODAL: 'close_switch_team_modal',
    SUBMIT_VIDEO_MODAL: 'close_submit_video_modal',
    MOVE_TO_TOP_CONFIRMATION_MODAL: 'close_move_to_top_confirmation_modal',
    SUBMIT_SCENARIO_MODE_VIDEOS_MODAL:
      'close_submit_scenario_mode_videos_modal',
    SUSPEND_USER_MODAL: 'close_suspend_user_modal',
    UPDATE_SCENE_MODAL: 'close_update_scene_modal',
    UPDATE_TEAM_GROUP_NAME_MODAL: 'close_update_team_group_name_modal',
    UPDATE_TEAM_NAME_MODAL: 'close_update_team_name_modal',
    UPDATE_USER_ATTRIBUTE_MODAL: 'close_update_user_attribute_modal',
  },

  CONFIRM: {
    CONTACT_FORM: 'confirm_contact_form',
  },

  CONTINUE_TO: {
    INPUT_PASSWORD: 'continue_to_input_password',
  },

  CREATE: {
    SCENE: 'create_scene',
    TEAM_GROUP: 'create_team_group',
    TEAM_WITH_OUTSIDE_USERS: 'create_team_with_outside_users',
  },

  DELETE: {
    SCENE: 'delete_scene',
    STEP: 'delete_step',
    STEP_GROUP: 'delete_step_group',
    TEAM: 'delete_team',
  },

  DETACH: {
    SCENE: 'detach_scene',
    TEAMS_FROM_TEAM_GROUP: 'detach_teams_from_team_group',
    USER: 'detach_user',
    USER_FROM_TEAM: 'detach_user_from_team',
  },

  FAILED_TO: {
    RESEND: {
      INVITATION_MAIL: 'failed_to_resend_invitation_mail',
    },
    APPEND_STEP: 'failed_to_append_step',
    CANCEL_USER_INVITATION: 'failed_to_cancel_user_invitation',
    CHANGE_PASSWORD: 'failed_to_change_password',
    CONFIRM_FORGOT_PASSWORD: 'failed_to_confirm_forgot_password',
    DELETE_TEAM: 'failed_to_delete_team',
    GET_LOGIN_METHOD: 'failed_to_get_login_method',
    LOGIN: 'failed_to_login',
    UPDATE_STEP: 'failed_to_update_step',
    RECORD: {
      BECAUSE_OF: {
        MAX_SIZE_LIMIT: 'failed_to_record_because_of_max_size_limit',
        MIN_SIZE_LIMIT: 'failed_to_record_because_of_min_size_limit',
      },
    },
    RESUME_USER: 'failed_to_resume_user',
    SEND: {
      PASSWORD_RESET_MAIL: 'failed_to_send_password_reset_mail',
    },
    SUBMIT: {
      ROLE_PLAYING: 'failed_to_submit_role_playing',
      SCENARIO_MODE_VIDEOS: 'failed_to_submit_scenario_mode_videos',
    },
    SUSPEND: {
      USER: 'failed_to_suspend_user',
    },
  },

  INVITE: {
    USERS: 'invite_users',
  },

  LOGIN: {
    WITH_GOOGLE: 'login_with_google',
    WITH_MICROSOFT: 'login_with_microsoft',
    WITH_ID_PASSWORD: 'login_with_id_password',
  },

  LOGOUT: {
    ON_LOGIN_PAGE: 'logout_on_login_page',
  },

  MOVE_TO: {
    ADMIN_USERS_FROM_SIDEBAR: 'move_to_admin_users_from_sidebar',
    ADMIN_TEAMS_FROM_SIDEBAR: 'move_to_admin_teams_from_sidebar',
    ADMIN_TEAM_GROUPS_FROM_SIDEBAR: 'move_to_admin_team_groups_from_sidebar',
    ADMIN_USERS: 'move_to_admin_users',
    APPEND_STEP: 'move_to_append_step',
    APPEND_STEP_TO_STEP_GROUP: 'move_to_append_step_to_step_group',
    CONTACT_FORM: 'move_to_contact_form',
    DASHBOARD: 'move_to_dashboard',
    EDIT_STEP: 'move_to_edit_step',
    EXTERNAL_TRANSMISSION: 'move_to_external_transmission',
    EXTERNAL_TRANSMISSION_SERVICE: 'move_to_external_transmission_service',
    FORGOT_PASSWORD: 'move_to_forgot_password',
    HOME_FROM_HEADER_LOGO: 'move_to_home_from_header_logo',
    LOGIN: 'move_to_login',
    MANAGER_REPORT: 'move_to_manager_report',
    MY_SCENE_DETAIL: 'move_to_my_scene_detail',
    MY_SCENES: 'move_to_my_scenes',
    MY_SCENES_BY_MORE_LINK_ON_SCENE:
      'move_to_my_scene_by_more_link_on_scene_item',
    NEXT_STEP: 'move_to_next_step',
    NEXT: {
      ON_PAGER: 'move_to_next_on_pager',
    },
    PRIVACY_POLICY: 'move_to_privacy_policy',
    ROLE_PLAYING_TOP: 'move_to_role_playing_top',
    SCENES: 'move_to_scenes',
    SCENE_DETAIL: 'move_to_scene_detail',
    SCENES_FROM_SIDEBAR: 'move_to_scenes_from_sidebar',
    START_ROLE_PLAY: 'move_to_start_role_play',
    STEP_LIST: 'move_to_step_list',
    TERMS_OF_USE: 'move_to_terms_of_use',
    TEAM_SUMMARY: 'move_to_team_summary',
    TEAM_SUMMARY_ON_MANAGER_REPORT: 'move_to_team_summary_on_manager_report',
    TEAM_SUMMARY_WITH_SCENE_FROM_SIDEBAR:
      'move_to_team_summary_with_scene_from_sidebar',
    TEAM_USERS_FROM_SIDEBAR: 'move_to_team_users_from_sidebar',
    USER_REPORT: 'move_to_user_report',
    USER_REPORT_BY_MORE_LINK_ON_MY_SUBMISSION:
      'move_to_user_report_by_more_link_on_my_submission',
    USER_REPORT_SUBMISSION: 'move_to_user_report_submission',
    USER_REPORT_STEP: 'move_to_user_report_step',
    USER_REPORT_STEP_ON_SUBMITTED: 'move_to_user_report_step_on_submitted',
    USER_REPORT_SCENE: 'move_to_user_report_scene',
    USER_REPORT_SCENE_ON_SUBMITTED: 'move_to_user_report_scene_on_submitted',
    USER_REPORT_SCENE_ON_MANAGER_REPORT:
      'move_to_user_report_scene_on_manager_report',
  },

  MOVE_BACK_TO: {
    CONTACT_FORM: 'move_back_to_contact_form',
    MY_SCENES_DETAIL: 'move_back_to_my_scenes_detail',
    PREVIOUS_PAGE: 'move_back_to_previous_page',
    PREVIOUS: {
      ON_PAGER: 'move_back_to_previous_on_pager',
    },
    PREVIOUS_SIDE_NAVI: 'move_back_to_previous_side_navi',
    SCENES: 'move_back_to_scenes',
    SCENE_DETAIL: 'move_back_to_scene_detail',
    EMAIL_INPUT: 'move_back_to_email_input',
  },

  OPEN: {
    ATTACH_SCENE_TO_TEAM_SCENE_FORM_MODAL:
      'open_attach_scene_to_team_scene_form_modal',
    ATTACH_TEAMS_TO_TEAM_GROUP_MODAL: 'open_attach_teams_to_team_group_modal',
    ATTACH_USER_TO_TEAM_MODAL: 'open_attach_user_to_team_modal',
    ATTACH_USER_MODAL: 'open_attach_user_modal',
    CREATE_TEAM_GROUP_MODAL: 'open_create_team_group_modal',
    CREATE_TEAM_MODAL: 'open_create_team_modal',
    DELETE_STEP_MODAL: 'open_delete_step_modal',
    DELETE_SCENE_MODAL: 'open_delete_scene_modal',
    INVITE_USERS_MODAL: 'open_invite_users_modal',
    MOVE_TO_TOP_CONFIRMATION_MODAL: 'open_move_to_top_confirmation_modal',
    SCENE_SUBMISSION_MODAL: 'open_scene_submission_modal',
    SCENE_DETAIL: 'open_scene_detail',
    SCENE_DETAIL_SLIDE: 'open_scene_detail_slide',
    SUBMIT_MODAL: 'open_submit_modal',
    SWITCH_TEAM_MODAL: 'open_switch_team_modal',
    UPDATE_SCENE_MODAL: 'open_update_scene_modal',
    TOOLTIP_CLARITY_ANALYSIS: 'open_tooltip_clarity_analysis',
    TOOLTIP_FILLER_COUNT_ANALYSIS: 'open_tooltip_filler_count_analysis',
    TOOLTIP_LENGTH_COMPARE_TO_MODEL_ANALYSIS:
      'open_tooltip_length_compare_to_model_analysis',
    TOOLTIP_PASS_CRITERIA: 'open_tooltip_pass_criteria',
    TOOLTIP_REQUIRED_NG_WORD_ANALYSIS: 'open_tooltip_required_ng_word_analysis',
    TOOLTIP_SENTENCE_SHORTNESS_ANALYSIS:
      'open_tooltip_sentence_shortness_analysis',
    TOOLTIP_SIMILARITY_ANALYSIS: 'open_tooltip_similarity_analysis',
    TOOLTIP_SLIDE_ON_USER_REPORT: 'open_tooltip_slide_on_user_report',
    TOOLTIP_TALK_SPEED_ANALYSIS: 'open_tooltip_talk_speed_analysis',
    TOOLTIP_TALK_SCRIPT_ON_APPEND_STEP_FORM:
      'open_tooltip_talk_script_on_append_step_form',
    TOOLTIP_TALK_SCRIPT_ON_UPDATE_STEP_FORM:
      'open_tooltip_talk_script_on_update_step_form',
    TOOLTIP_PRACTICE_MODE: 'open_tooltip_practice_mode',
  },

  PUBLISH: {
    SCENE: 'publish_scene',
  },

  REGISTER: {
    USER_NAME: 'register_user_name',
  },

  RELOAD_ON: {
    MEDIA_ERROR_MODAL: 'reload_on_media_error_modal',
  },

  RERECORD: {
    VIDEO: 'rerecord_video',
  },

  RESEND: {
    INVITATION_MAIL: 'resend_invitation_mail',
  },

  RESET: {
    CONTACT_FORM: 'reset_contact_form',
  },

  RESUME: {
    USER: 'resume_user',
  },

  SEND: {
    PASSWORD_RESET_EMAIL: 'send_password_reset_email',
  },

  START: {
    ROLE_PLAY: 'start_role_play',
  },

  SUBMIT: {
    CONTACT_FORM: 'submit_contact_form',
    SCENARIO_MODE_VIDEOS: 'submit_scenario_mode_videos',
    VIDEO: 'submit_video',
  },

  SUSPEND: {
    USER: 'suspend_user',
  },

  SWITCH: {
    EDIT_MODE: 'switch_edit_mode',
    FILTER: {
      TO_ALL: 'switch_filter_to_all',
      TO_NOT_SUBMITTED: 'switch_filter_to_not_submitted',
      TO_IN_PROGRESS: 'switch_filter_to_in_progress',
      TO_COMPLETED: 'switch_filter_to_completed',
    },
    PREVIEW_MODE: 'switch_preview_mode',
    TAB_IN_SUBMISSION_TIMES: 'switch_tab_in_submission_times',
    TEAM: 'switch_team',
  },

  UPDATE: {
    SCENE: 'update_scene',
    SCENE_PRACTICE_INFORMATION: 'update_scene_practice_information',
    STEP: 'update_step',
    STEP_NUMBER_PREVIOUS: 'update_step_number_previous',
    STEP_NUMBER_NEXT: 'update_step_number_next',
    TEAM_GROUP_NAME: 'update_team_group_name',
    TEAM_NAME: 'update_team_name',
    USER_ATTRIBUTE: 'update_user_attribute',
  },
}
