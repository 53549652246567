import { Image } from '@chakra-ui/react'
import NextLink from 'next/link'
import { Link } from '@/components/common/Link'
import { ACTIONS } from '@/lib/comlinkKey'

const LOGO_IMAGE = {
  src: '/logo.svg',
  alt: 'トレキャン',
}

export const Logo = () => {
  return (
    <Link
      as={NextLink}
      href='/'
      comlinkPushParams={{ action: ACTIONS.MOVE_TO.HOME_FROM_HEADER_LOGO }}
      mb={{ base: 0, sm: '1.5' }}
    >
      <Image
        src={LOGO_IMAGE.src}
        alt={LOGO_IMAGE.alt}
        width={{ base: '6rem', sm: '9rem' }}
        h='100%'
      />
    </Link>
  )
}
