export const QUERY_KEY = {
  listDraftTeamScenes: 'listDraftTeamScenes',
  listPublishedTeamScenes: 'listPublishedTeamScenes',
  listMasterScenes: 'listMasterScenes',
  listMyScenes: 'listMyScenes',
  getMyScene: 'getMyScene',
  getMySceneScenarioMode: 'getMySceneScenarioMode',
  getScene: 'getScene',
  getPublishedScene: 'getPublishedScene',
  getPublishedSceneScenarioMode: 'getPublishedSceneScenarioMode',
  getSceneStep: 'getSceneStep',
  listTeamActiveUsers: 'listTeamActiveUsers',
  listActiveUsersOutsideTeam: 'listActiveUsersOutsideTeam',
  listActiveUsersOutsideTeamForAdmin: 'listActiveUsersOutsideTeamForAdmin',
  listSceneStepAnalysisWords: 'listSceneStepAnalysisWords',
  getInvitationStatus: 'getInvitationStatus',
  listPublishedScenes: 'listPublishedScenes',
  listSceneUserSummaries: 'listSceneUserSummaries',
  listSceneLatestSubmissions: 'listSceneLatestSubmissions',
  listTeamLatestSubmissions: 'listTeamLatestSubmissions',
  getSubmissionAnalysis: 'getSubmissionAnalysis',
  getSceneSubmissionAnalysis: 'getSceneSubmissionAnalysis',
  listSubmissions: 'listSubmissions',
  listSceneSubmissions: 'listSceneSubmissions',
  listLatestMySubmissions: 'listLatestMySubmissions',
  listMyManagingTeamGroups: 'listMyManagingTeamGroups',
  getUser: 'getUser',
  getMyUserAttributes: 'getMyUserAttributes',
  getSceneTeamSummary: 'getSceneTeamSummary',
  getTeam: 'getTeam',
  listSceneUserRoleplayingCountRanking: 'listSceneRoleplayingCountRanking',
  getMyRoleplayingSummary: 'getMyRoleplayingSummary',
  listOrganizationActiveUserProfilesForAdmin:
    'listOrganizationActiveUserProfilesForAdmin',
  listInvitedUsersForAdmin: 'listInvitedUsersForAdmin',
  getUserAttributesForAdmin: 'getUserAttributesForAdmin',
  listTeamGroupsForAdmin: 'listTeamGroupsForAdmin',
  listTeamsForAdmin: 'listTeamsForAdmin',
  listTeamActiveUsersForAdmin: 'listTeamActiveUsersForAdmin',
  listTeamsOutsideTeamGroupForAdmin: 'listTeamsOutsideTeamGroupForAdmin',
  listSuspendedUsersForAdmin: 'listSuspendedUsersForAdmin',
  getLoginMethodWithProvider: 'getLoginMethodWithProvider',
  prepareSceneScenarioMode: 'prepareSceneScenarioMode',
  getScenePracticeMode: 'getScenePracticeMode',
  getSceneScenarioMode: 'getSceneScenarioMode',
} as const
