import type { UrlObjectType } from '@/components/common/Link'
/**
 * 指定したUrlObjectの値をstringで返すヘルパー関数
 * @return {string} パラメータ付きのURLのstring
 */
export const formatUrl = (url: string | UrlObjectType): string => {
  if (typeof url === 'string') return url

  const { pathname, query } = url
  const urlSearchParam = new URLSearchParams(query).toString()
  return `${pathname}?${urlSearchParam}`
}
