import { Center, CircularProgress } from '@chakra-ui/react'

export const Loading = () => {
  return (
    <Center h='100vh'>
      <CircularProgress
        isIndeterminate
        color='teal.500'
        size='100px'
        thickness='4px'
      />
    </Center>
  )
}
