import { OrbiterServicePromiseClient } from '@blue-agency/proton/web/v2/orbiter/orbiter_service_grpc_web_pb'
import { getCurrentSession } from '@/lib/auth'
import { comlinkPush } from '@/lib/comlink'
import { UnauthenticatedError } from '@/utils/UnauthenticatedError'
import { callWithRetry } from './callWithRetry'
import { CustomOrbiterGrpcError } from './customGrpcError'

const hostname = process.env.NEXT_PUBLIC_API_HOST
if (!hostname) throw new Error('hostname not found')

const orbiterClient = new OrbiterServicePromiseClient(hostname)

declare global {
  interface Window {
    __GRPCWEB_DEVTOOLS__(clients: OrbiterServicePromiseClient[]): void
  }
}

if (process.env.NEXT_PUBLIC_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // GRPC-web ブラウザ拡張を有効にするための設定
  const enableDevTools =
    globalThis.window?.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      // GRPC-web ブラウザ拡張を入れてない場合は何もしない
    })
  enableDevTools([orbiterClient])
}

type RpcNames = Exclude<keyof OrbiterServicePromiseClient, 'constructor'>

type Options = {
  public: boolean
}

type AuthMetadata = {
  authorization: string
}

export const callOrbiterService = async <T extends RpcNames>(
  rpcName: T,
  req: Parameters<OrbiterServicePromiseClient[T]>[0],
  options: Options = { public: false }
): Promise<ReturnType<OrbiterServicePromiseClient[T]>> => {
  let authMetadata: AuthMetadata | undefined
  const comlinkMetadata: Record<string, string> = { rpc: rpcName }

  if (!options.public) {
    const session = await getCurrentSession()
    if (!session.valid) {
      throw new UnauthenticatedError('Failed to get current session')
    }

    authMetadata = { authorization: session.idToken }
    comlinkMetadata['userGuid'] = session.guid
  }

  comlinkPush({
    action: 'call_rpc',
    metadata: comlinkMetadata,
  })

  // @ts-expect-error: `Promise<Resp1 | Resp2 | ...>` が ReturnType<OrbiterServicePromiseClient[T]> に割り当てられないというエラーが出るが、実用上問題はないため、エラーを無視する
  return callWithRetry(async () => {
    // `req` の型が、RPCそれぞれのパラメータのunionになっている (e.g. `P1 | P2`) ため、
    // 例えば `orbiterClient[rpcName]` が `P1` をパラメータとして要求するメソッドだったときに `P1 | P2` という型の値を
    // 使って呼び出すことはTypeScriptの制約上許されない。しかし、この `callOrbiterService` の実装を見れば分かる通り、
    // `P1` が要求されるRPCの場合はパラメータとして `P1` が渡されることが明らかなので、型エラーを無視する
    // 何かTypeScript的にスマートな解決策があったらぜひ直してください
    // @ts-expect-error: for above reason
    return orbiterClient[rpcName](req, authMetadata).catch((e) => {
      const customErr = new CustomOrbiterGrpcError(e)
      comlinkPush({
        action: 'rpc_error',
        metadata: {
          ...comlinkMetadata,
          message: customErr.message ?? '',
          metadata: JSON.stringify(customErr.metadata) ?? '',
          code: customErr.code.toString() ?? '',
        },
      })
      throw e
    })
  })
}
