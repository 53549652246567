import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { createContext, useEffect, useMemo } from 'react'
import { Loading } from '@/components/common/Loading'
import { useSession } from '@/hooks/auth/useSession'
import { useLoginRedirectTo } from '@/hooks/useLoginRedirectTo'
import { useInitializeComlinkUser } from '@/lib/comlink'
import { QUERY_KEY } from '@/services/queryKeyService'
import { requestGetMyUserAttributes } from '@/services/rpcService/requestMyUserAttributes'
import type { MyUserAttributes } from '@/services/rpcService/requestMyUserAttributes'
import type { FC, ReactElement } from 'react'

const STATIC_PAGE_PATHS = ['/terms', '/external-transmission']

const AUTH_PAGE_PATHS = [
  '/auth/login',
  '/auth/forgot_password',
  '/auth/confirm_forgot_password',
  '/auth/password_change',
]

type AuthContextState = {
  userAttributes?: MyUserAttributes
}

export const AuthContext = createContext<AuthContextState>({})

type AuthProviderProps = {
  children: ReactElement
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const router = useRouter()
  const { setLoginRedirectTo } = useLoginRedirectTo()
  const isStaticPage = useMemo(
    () =>
      STATIC_PAGE_PATHS.some(
        (staticPagePath) => router.pathname === staticPagePath
      ),
    [router.pathname]
  )
  // NOTE: staticではないが、ログアウトページの場合は認証チェックをスキップするため
  const isLogoutPage = useMemo(
    () => router.pathname === '/auth/logout',
    [router.pathname]
  )
  const isAuthPage = useMemo(
    () =>
      AUTH_PAGE_PATHS.some((authPagePath) => router.pathname === authPagePath),
    [router.pathname]
  )
  const { session, isLoading: isSessionLoading } = useSession()
  const { data: userAttributes, isPending: isAttributesLoading } = useQuery({
    queryKey: [QUERY_KEY.getMyUserAttributes],
    queryFn: () => requestGetMyUserAttributes(),
    enabled: !!session?.valid && !(isStaticPage || isLogoutPage),
  })
  useInitializeComlinkUser(userAttributes)

  useEffect(() => {
    if (!isAuthPage && !isLogoutPage && !isSessionLoading && !session?.valid) {
      setLoginRedirectTo(router.asPath)
      router.push('/auth/login')
    }
  }, [
    isAuthPage,
    isLogoutPage,
    isSessionLoading,
    router,
    session?.valid,
    setLoginRedirectTo,
  ])

  if (isSessionLoading) {
    return <Loading />
  }

  if ((!session?.valid && isAuthPage) || isStaticPage || isLogoutPage) {
    return children
  }

  if (isAttributesLoading || !userAttributes) {
    return <Loading />
  }

  return (
    <AuthContext.Provider value={{ userAttributes }}>
      {children}
    </AuthContext.Provider>
  )
}
