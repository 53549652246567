import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdReportProblem } from 'react-icons/md'
import { Alone } from '@/components/common/Alone'
import { PrimaryButton } from '@/components/common/Button'
import { Layout } from '@/components/common/Layout'
import { ACTIONS } from '@/lib/comlinkKey'

type Props = {
  children: React.ReactNode
}

const Content = (props: Props) => {
  return (
    <Flex
      flexDir='column'
      h='100%'
      p='40px 16px'
      alignItems='center'
      bg='gray.100'
    >
      {props.children}
    </Flex>
  )
}

export const GeneralErrorPage = () => {
  return (
    <Layout isUserLogin={false}>
      <Content>
        <Text fontSize='lg'>エラーが発生しました。</Text>
        <Text fontSize='lg'>時間をおいて再度お試しください。</Text>
        <Icon as={MdReportProblem} color='red.500' boxSize='102' mt='36px' />
      </Content>
    </Layout>
  )
}

export const NotFoundErrorPage = () => {
  return (
    <Layout isUserLogin={false}>
      <Content>
        <Text fontSize='lg'>お探しのページは存在しません。</Text>
        <Text fontSize='lg' align='center'>
          URLが誤っているか、データが削除されている可能性があります。
        </Text>
        <Text fontSize='7xl' color='red.500' fontWeight='bold'>
          404
        </Text>
      </Content>
    </Layout>
  )
}

export const UnauthenticatedErrorPage = () => {
  return (
    <Layout isUserLogin={false}>
      <Content>
        <Text fontSize='lg' marginBottom='2em'>
          ログインが必要です。
        </Text>
        <PrimaryButton
          size='lg'
          comlinkPushParams={{ action: ACTIONS.MOVE_TO.LOGIN }}
          href='/auth/login'
        >
          ログインページへ進む
        </PrimaryButton>
      </Content>
    </Layout>
  )
}

export const AloneErrorPage = () => {
  return (
    <Layout isUserLogin={false}>
      <Content>
        <Alone />
      </Content>
    </Layout>
  )
}

export const PermissionDeniedErrorPage = () => {
  return (
    <Layout isUserLogin={false}>
      <Content>
        <Text fontSize='lg' textAlign='center'>
          このページを閲覧する権限がありません。
        </Text>
        <Icon as={MdReportProblem} color='red.500' boxSize='102' mt='36px' />
      </Content>
    </Layout>
  )
}
