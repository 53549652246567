import { Box, Divider, Flex } from '@chakra-ui/react'
import Head from 'next/head'
import { Footer } from '@/components/common/Footer'
import { Header } from '@/components/common/Header'

export type PageMetaProps = {
  title?: string
  description?: string
  // TODO: 必要に応じてmeta情報を増やす
}

type Props = {
  children: React.ReactNode
  headerRef?: React.RefObject<HTMLHRElement>
  isUserLogin?: boolean
  showFooter?: boolean
  pageMeta?: PageMetaProps
}

export const Layout = ({
  children,
  headerRef,
  isUserLogin = true,
  showFooter = true,
  pageMeta,
}: Props) => {
  return (
    <Flex flexDir='column' h='100vh'>
      <Head>
        <title>{pageMeta?.title && `${pageMeta.title}｜`}トレキャン</title>
        {pageMeta?.description && (
          <meta name='description' content={pageMeta.description} />
        )}
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta
          property='og:title'
          content={
            pageMeta?.title ? `${pageMeta.title}｜トレキャン` : 'トレキャン'
          }
        />
        {pageMeta?.description && (
          <meta name='og:description' content={pageMeta.description} />
        )}
        {/* TODO: 必要に応じてmeta情報を増やす */}
      </Head>
      <Header isUserLogin={isUserLogin} />
      <Divider ref={headerRef} />
      <Box flexGrow='1'>{children}</Box>
      {showFooter && <Footer showMenuWithLogin={isUserLogin} />}
    </Flex>
  )
}
