import { useEffect } from 'react'
import type { MyUserAttributes } from '@/services/rpcService/requestMyUserAttributes'
import type {
  ComlinkPushParams,
  ComlinkPushOptions,
} from '@blue-agency/comlink/dist/src/app'

type ActionName = string
type ActionNameOrParams = ActionName | Omit<ComlinkPushParams, 'group'>

const applicationName = 'orbiter-front'

const comlinkHost = process.env.NEXT_PUBLIC_COMLINK_HOST
if (!comlinkHost) {
  throw new Error('NEXT_PUBLIC_COMLINK_HOST is required')
}

export const comlinkPush = async (
  actionNameOrParams: ActionNameOrParams,
  opts?: ComlinkPushOptions
) => {
  // NOTE: 通常の静的インポートだと、SSR時に `window` を参照することができずエラーになる
  // https://nextjs.org/docs/advanced-features/dynamic-import#with-external-libraries
  const { Comlink } = await import('@blue-agency/comlink')
  await Comlink.setHost(comlinkHost)

  let params: ComlinkPushParams

  if (typeof actionNameOrParams === 'string') {
    params = {
      action: actionNameOrParams,
      group: applicationName,
    }
  } else {
    params = {
      ...actionNameOrParams,
      group: applicationName,
    }
  }

  await Comlink.push(params, opts)
}

export const useInitializeComlinkUser = (
  userAttributes: MyUserAttributes | undefined
) => {
  useEffect(() => {
    import('@blue-agency/comlink').then(({ Comlink }) => {
      Comlink.setHost(comlinkHost).then(() => {
        if (userAttributes) {
          Comlink.setUser({
            userGuid: userAttributes.user.guid,
            organizationGuid: userAttributes.organization.guid,
          })
        } else {
          Comlink.setUser({})
        }
      })
    })
  }, [userAttributes])
}
