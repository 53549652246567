import { Box, Center, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { BiCoffee } from 'react-icons/bi'

export const Alone = () => {
  return (
    <Flex flexDir='column' mt='2em'>
      <Center>
        <Icon
          display='block'
          as={BiCoffee}
          w='10em'
          h='10em'
          color='gray.600'
        />
      </Center>
      <Box py='2em' px='1em'>
        <Heading textAlign='center' size='sm' pb='1em'>
          まだ、どのチームからも招待を受けていません。
        </Heading>
        <Text>
          チームまたはマネージャーから招待されるまで、しばらく待ってから、再度アクセスして下さい。
        </Text>
      </Box>
    </Flex>
  )
}
