import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useMemo } from 'react'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import {
  MdMenu,
  MdOutlineSettings,
  MdOutlineSpaceDashboard,
  MdRepeat,
} from 'react-icons/md'
import { Link } from '@/components/common/Link'
import { useAuth } from '@/hooks/auth/useAuth'
import { useCurrentTeam } from '@/hooks/my/useCurrentTeam'
import { ACTIONS } from '@/lib/comlinkKey'
import type { BelongingTeam } from '@/services/teamService/belongingTeam'

export const HeaderMenu = () => {
  const { userAttributes } = useAuth()
  const { currentTeam } = useCurrentTeam()

  const isLeader = useMemo(() => {
    return currentTeam?.role === 'leader'
  }, [currentTeam?.role])

  const isManager = useMemo(() => {
    return !!userAttributes?.isManager
  }, [userAttributes?.isManager])

  const isAdmin = useMemo(() => {
    return !!userAttributes?.isAdmin
  }, [userAttributes?.isAdmin])

  return (
    <Menu autoSelect={false} gutter={0}>
      <MenuButton
        aria-label='メニューを開く'
        w='9'
        h='9'
        m='auto'
        borderRadius='full'
        _hover={{
          bgColor: 'gray.100',
        }}
        _active={{
          bgColor: 'gray.200',
        }}
      >
        <Flex alignItems='center' justifyContent='center'>
          <MdMenu size='1.5rem' />
        </Flex>
      </MenuButton>
      <MenuList>
        <Link
          as={NextLink}
          href='/dashboard'
          comlinkPushParams={{ action: ACTIONS.MOVE_TO.DASHBOARD }}
          _hover={{ textDecoration: 'none' }}
        >
          <MenuItem
            as='span'
            icon={<MdOutlineSpaceDashboard size='1.125rem' />}
          >
            ダッシュボード
          </MenuItem>
        </Link>
        {currentTeam && (
          <Link
            as={NextLink}
            href='/my-scenes'
            comlinkPushParams={{ action: ACTIONS.MOVE_TO.MY_SCENES }}
            _hover={{ textDecoration: 'none' }}
          >
            <MenuItem as='span' icon={<MdRepeat size='1.125rem' />}>
              ロールプレイング
            </MenuItem>
          </Link>
        )}

        {userAttributes && (isManager || currentTeam) && (
          <ReportsMenuItem
            userGuid={userAttributes.user.guid}
            isManager={isManager}
            currentTeam={currentTeam}
          />
        )}
        {(isAdmin || isLeader) && (
          <ManagementMenuItem isAdmin={isAdmin} isLeader={isLeader} />
        )}
      </MenuList>
    </Menu>
  )
}

const ReportsMenuItem = ({
  userGuid,
  isManager,
  currentTeam,
}: {
  userGuid: string
  isManager: boolean
  currentTeam: BelongingTeam | undefined
}) => {
  /** Memo:
      レポート系が出てくる条件は、マネージャー または チームに所属している
      レポートがリンクになる条件は、チームに属している
      レポートがリンクにならない条件は、マネージャー かつ !チームに属しているとき
      つまり、マネージャー または チームに属している条件下で、マネージャー かつ !チームに属しているかどうかで分岐
   */
  const MenuLinks = () => {
    return (
      <>
        {currentTeam && (
          <Box as='ul' role='group'>
            {isManager && (
              <li style={{ listStyle: 'none' }}>
                <Link
                  as={NextLink}
                  href='/manager-report'
                  comlinkPushParams={{
                    action: ACTIONS.MOVE_TO.MANAGER_REPORT,
                  }}
                  _hover={{ textDecoration: 'none' }}
                >
                  <MenuItem as='span' color='gray.900' pl='6'>
                    グループレポート
                  </MenuItem>
                </Link>
              </li>
            )}
            <li style={{ listStyle: 'none' }}>
              <Link
                as={NextLink}
                href={'/team-summary?teamGuid=' + currentTeam.guid}
                comlinkPushParams={{
                  action: ACTIONS.MOVE_TO.TEAM_SUMMARY,
                }}
                _hover={{ textDecoration: 'none' }}
              >
                <MenuItem as='span' color='gray.900' pl='6'>
                  チームレポート
                </MenuItem>
              </Link>
            </li>
            <li style={{ listStyle: 'none' }}>
              <Link
                as={NextLink}
                href={
                  '/user-report?userGuid=' +
                  userGuid +
                  '&teamGuid=' +
                  currentTeam.guid
                }
                comlinkPushParams={{
                  action: ACTIONS.MOVE_TO.USER_REPORT,
                }}
                _hover={{ textDecoration: 'none' }}
              >
                <MenuItem as='span' color='gray.900' pl='6'>
                  ユーザーレポート
                </MenuItem>
              </Link>
            </li>
          </Box>
        )}
      </>
    )
  }
  return currentTeam ? (
    <Box>
      <Flex alignItems='center' px='3' py='2' gap='0.8rem'>
        <HiOutlineDocumentReport size='1.125rem' />
        <Text as='span' fontWeight='bold'>
          レポート
        </Text>
      </Flex>
      <MenuLinks />
    </Box>
  ) : (
    <Link
      as={NextLink}
      href='/manager-report'
      comlinkPushParams={{ action: ACTIONS.MOVE_TO.MANAGER_REPORT }}
      _hover={{ textDecoration: 'none' }}
    >
      <MenuItem
        as='span'
        color='gray.900'
        icon={<HiOutlineDocumentReport size='1.125rem' />}
      >
        レポート
      </MenuItem>
    </Link>
  )
}

const ManagementMenuItem = ({
  isAdmin,
  isLeader,
}: {
  isAdmin: boolean
  isLeader: boolean
}) => {
  /** Memo:
      管理系が出てくる条件は、管理者 or リーダー
      管理がリンクになる条件は (管理者 and !リーダー) または (!管理者 and リーダー)
      リンクにならない条件は、管理者 and リーダー
      つまり、管理者 or リーダーの条件で、管理者 and リーダーの場合で分岐
   */
  const MenuLinks = () => {
    return (
      <Box as='ul' role='group'>
        <li style={{ listStyle: 'none' }}>
          <Link
            as={NextLink}
            href='/admin/users'
            comlinkPushParams={{ action: ACTIONS.MOVE_TO.ADMIN_USERS }}
            _hover={{ textDecoration: 'none' }}
          >
            <MenuItem as='span' pl='3' color='gray.900'>
              <Box pl='3'>組織管理</Box>
            </MenuItem>
          </Link>
        </li>
        <li style={{ listStyle: 'none' }}>
          <Link
            as={NextLink}
            href='/scenes'
            comlinkPushParams={{ action: ACTIONS.MOVE_TO.SCENES }}
            _hover={{ textDecoration: 'none' }}
          >
            <MenuItem as='span' pl='3' color='gray.900'>
              <Box pl='3'>チーム管理</Box>
            </MenuItem>
          </Link>
        </li>
      </Box>
    )
  }

  return isAdmin && isLeader ? (
    <Box>
      <Flex alignItems='center' px='3' py='2' gap='0.8rem'>
        <MdOutlineSettings size='1.125rem' />
        <Text as='span' fontWeight='bold'>
          管理
        </Text>
      </Flex>
      <MenuLinks />
    </Box>
  ) : isAdmin ? (
    <Link
      as={NextLink}
      href='/admin/users'
      comlinkPushParams={{ action: ACTIONS.MOVE_TO.ADMIN_USERS }}
      _hover={{ textDecoration: 'none' }}
    >
      <MenuItem
        as='span'
        pl='3'
        color='gray.900'
        icon={<MdOutlineSettings size='18px' />}
      >
        管理
      </MenuItem>
    </Link>
  ) : (
    <Link
      as={NextLink}
      href='/scenes'
      comlinkPushParams={{ action: ACTIONS.MOVE_TO.SCENES }}
      _hover={{ textDecoration: 'none' }}
    >
      <MenuItem
        as='span'
        pl='3'
        color='gray.900'
        icon={<MdOutlineSettings size='18px' />}
      >
        管理
      </MenuItem>
    </Link>
  )
}
