import { Amplify } from '@aws-amplify/core'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import useScript from 'react-script-hook'
import {
  AloneErrorPage,
  GeneralErrorPage,
  NotFoundErrorPage,
  PermissionDeniedErrorPage,
  UnauthenticatedErrorPage,
} from '@/components/common/ErrorPage'
import { useAppRefresher } from '@/hooks/useAppRefresher'
import awsConfig from '@/lib/awsConfiguration'
import { comlinkPush } from '@/lib/comlink'
import { theme } from '@/lib/theme'
import { initTracking } from '@/lib/tracker'
import {
  CustomGrpcError,
  PermissionDenied,
} from '@/services/rpcService/customGrpcError'
import { AloneError } from '@/utils/AloneError'
import { AuthProvider } from '@/utils/AuthProvider'
import { NotFoundError } from '@/utils/notFoundError'
import { UnauthenticatedError } from '@/utils/UnauthenticatedError'
import type { AppProps } from 'next/app'
import type { FallbackProps } from 'react-error-boundary'

import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/500.css'
import '@fontsource/noto-sans-jp/700.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@/styles/date-picker.scss'

Amplify.configure(awsConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      throwOnError: true,
    },
  },
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  useNewRelic()
  useTrackOpenPage()
  useAlertbox()

  return (
    <Sentry.ErrorBoundary>
      <ChakraProvider theme={theme}>
        <MyErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <RefreshLatestProvider>
                <Component {...pageProps} />
                <ReactQueryDevtools initialIsOpen={false} />
              </RefreshLatestProvider>
            </AuthProvider>
          </QueryClientProvider>
        </MyErrorBoundary>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  )
}

const RefreshLatestProvider = ({ children }: { children: React.ReactNode }) => {
  useAppRefresher()
  return <>{children}</>
}

type MyErrorBoundaryProps = {
  children: React.ReactNode
}
const MyErrorBoundary: React.FC<MyErrorBoundaryProps> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
}

const Fallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = () => {
      resetErrorBoundary()
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, resetErrorBoundary])

  if (error instanceof CustomGrpcError) {
    if (error.isNotFound) {
      return <NotFoundErrorPage />
    }

    if (error.isUnauthenticated) {
      return <UnauthenticatedErrorPage />
    }
  }

  if (error instanceof NotFoundError) {
    return <NotFoundErrorPage />
  }

  if (error instanceof UnauthenticatedError) {
    return <UnauthenticatedErrorPage />
  }

  if (error instanceof AloneError) {
    return <AloneErrorPage />
  }

  if (error instanceof PermissionDenied) {
    return <PermissionDeniedErrorPage />
  }

  Sentry.captureException(error)
  return <GeneralErrorPage />
}

export default MyApp

const useTrackOpenPage = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      comlinkPush({
        action: 'open_page',
        metadata: {
          width: window.screen.width.toString(),
          height: window.screen.height.toString(),
        },
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}

initTracking({
  click: (event, comlinkPushParams) => {
    const element = event.currentTarget
    const metadataBase = {
      tagName: element.tagName,
      label: element.innerText,
      className: element.className,
    }
    const metadata =
      element instanceof HTMLAnchorElement
        ? { ...metadataBase, href: element.href }
        : { ...metadataBase }

    comlinkPush({
      ...comlinkPushParams,
      type: 'manual_activity',
      action: comlinkPushParams.action || `click_${element.innerText}`,
      metadata: comlinkPushParams.metadata
        ? { ...metadata, ...comlinkPushParams.metadata }
        : metadata,
      amount: undefined,
    })
  },
})

const ALERT_BOX_SCRIPT_URL = process.env.NEXT_PUBLIC_ALERT_BOX_SCRIPT_URL
const ALERT_BOX_STATUS_JSON_URL =
  process.env.NEXT_PUBLIC_ALERT_BOX_STATUS_JSON_URL

declare global {
  interface Window {
    Alertbox: {
      push(jsonUrl: string): void
    }
  }
}

const useAlertbox = () => {
  const [loading] = useScript({ src: ALERT_BOX_SCRIPT_URL ?? null })
  useEffect(() => {
    if (loading) return
    if (!ALERT_BOX_STATUS_JSON_URL) return
    window.Alertbox.push(ALERT_BOX_STATUS_JSON_URL)
  }, [loading])
}

const useNewRelic = () => {
  useEffect(() => {
    const accountId = process.env.NEXT_PUBLIC_NEW_RELIC_ACCOUNT_ID
    const applicationId = process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID
    const licenseKey = process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY
    const allowedOrigins = process.env.NEXT_PUBLIC_NEW_RELIC_ALLOWED_ORIGINS
    if (accountId && applicationId && licenseKey && allowedOrigins) {
      import('@newrelic/browser-agent/loaders/browser-agent').then(
        ({ BrowserAgent }) => {
          const options = {
            init: {
              distributed_tracing: {
                enabled: true,
                cors_use_newrelic_header: true,
                cors_use_tracecontext_headers: true,
                allowed_origins: allowedOrigins.split(','),
              },
              privacy: { cookies_enabled: true },
              ajax: { deny_list: ['bam.nr-data.net'] },
            },
            info: {
              beacon: 'bam.nr-data.net',
              errorBeacon: 'bam.nr-data.net',
              licenseKey: licenseKey,
              applicationID: applicationId,
              sa: 1,
            },
            loader_config: {
              accountID: accountId,
              trustKey: accountId, // same as accountID
              agentID: applicationId, // same as applicationID
              licenseKey: licenseKey,
              applicationID: applicationId,
            },
          }
          new BrowserAgent(options)
        }
      )
    }
  }, [])
}
