import { Flex, Text, VisuallyHidden } from '@chakra-ui/react'
import NextLink from 'next/link'
import { ACTIONS } from '@/lib/comlinkKey'
import { Link } from './Link'

type Props = {
  showMenuWithLogin: boolean
}

export const Footer = ({ showMenuWithLogin }: Props) => {
  return (
    <Flex
      as='footer'
      bg='gray.600'
      w='100%'
      py='6'
      px={{ base: '6', sm: '4' }}
      color='white'
      gap={{ base: '12', sm: '6' }}
      flexDirection='column'
      alignItems={{ base: 'flex-start', sm: 'center' }}
    >
      <Flex
        as='nav'
        aria-label='サブメニュー'
        display='inline-flex'
        flexDirection={{ base: 'column', sm: 'row' }}
        sx={{ gap: { base: '5', sm: '6' } }}
      >
        <Link
          as={NextLink}
          href='https://stadium.co.jp/policy/privacy'
          comlinkPushParams={{ action: ACTIONS.MOVE_TO.PRIVACY_POLICY }}
          isExternal
        >
          プライバシーポリシー
          <VisuallyHidden>（別窓で開く）</VisuallyHidden>
        </Link>
        <Link
          as={NextLink}
          href='/terms'
          comlinkPushParams={{ action: ACTIONS.MOVE_TO.TERMS_OF_USE }}
          isExternal
        >
          利用規約
          <VisuallyHidden>（別窓で開く）</VisuallyHidden>
        </Link>
        <Link
          as={NextLink}
          href='/external-transmission'
          comlinkPushParams={{ action: ACTIONS.MOVE_TO.EXTERNAL_TRANSMISSION }}
          isExternal
        >
          情報の外部送信について
          <VisuallyHidden>（別窓で開く）</VisuallyHidden>
        </Link>
        {showMenuWithLogin && (
          <Link
            as={NextLink}
            href='/contact/form'
            comlinkPushParams={{ action: ACTIONS.MOVE_TO.CONTACT_FORM }}
          >
            お問い合わせ
          </Link>
        )}
      </Flex>
      <Text as='small' fontSize='xs'>
        &copy; STADIUM 2022
      </Text>
    </Flex>
  )
}
