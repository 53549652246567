import { Spacer } from '@chakra-ui/react'
import { AccountMenu } from './AccountMenu'
import { HeaderMenu } from './HeaderMenu'
import { Logo } from './Logo'

export const SpHeaderNavi = () => {
  return (
    <>
      <Logo />
      <Spacer />
      <AccountMenu />
      <HeaderMenu />
    </>
  )
}
