import { useLocalStorage } from 'react-use'
import { LOCAL_STORAGE_KEY } from '@/services/storageService/localStorageKey'

export const useLoginRedirectTo = () => {
  const [loginRedirectTo, setLoginRedirectTo] = useLocalStorage<string>(
    LOCAL_STORAGE_KEY.loginRedirectTo,
    ''
  )

  return { loginRedirectTo, setLoginRedirectTo }
}
