import { IconButton as ChakraIconButton, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { withComlinkPushOnClick } from '@/lib/withComlinkPushOnClick'
import type { Props as ButtonProps } from '@/components/common/Button/PrimaryButton'
import type { IconButtonProps } from '@chakra-ui/react'

type Props = ButtonProps & Pick<IconButtonProps, 'aria-label' | 'icon'>

export const IconButton = withComlinkPushOnClick(
  ({ href, icon, isDisabled, ...props }: Props) => {
    if (href && !isDisabled) {
      return (
        <Link
          as={NextLink}
          href={href}
          display='inline-grid'
          placeContent='center'
          borderRadius='full'
          _hover={{ bgColor: 'gray.100' }}
        >
          <ChakraIconButton
            as='span'
            icon={icon}
            variant='ghost'
            borderRadius='full'
            {...props}
          />
        </Link>
      )
    }
    return (
      <ChakraIconButton
        icon={icon}
        variant='ghost'
        borderRadius='full'
        isDisabled={isDisabled}
        {...props}
      />
    )
  }
)
