import { GetMyUserAttributesRequest } from '@blue-agency/proton/web/v2/orbiter/orbiter_service_pb'
import { fromProtoTeamBelongingRole } from './fromProtoEnum'
import { fromProtoName, fromProtoNameKana } from './fromProtoName'
import { callOrbiterService } from './orbiterClient'
import type { Organization } from '@/services/organizationService/organization'
import type { BelongingTeam } from '@/services/teamService/belongingTeam'
import type { ActiveUser } from '@/services/userService/user'
import type { GetMyUserAttributesResponse } from '@blue-agency/proton/web/v2/orbiter/orbiter_service_pb'

export type MyUserAttributes = {
  user: ActiveUser
  organization: Organization
  belongingTeams: BelongingTeam[]
  isManager: boolean
  isAdmin: boolean
}

type MyUserAttributesFetcher = () => Promise<MyUserAttributes>

export const requestGetMyUserAttributes: MyUserAttributesFetcher = async () => {
  const req = new GetMyUserAttributesRequest()

  const res = await callOrbiterService('getMyUserAttributes', req)

  return fromGetMyUserAttributes(res)
}

const fromGetMyUserAttributes = (
  res: GetMyUserAttributesResponse
): MyUserAttributes => {
  const name = fromProtoName(res.getName())
  const nameKana = fromProtoNameKana(res.getNameKana())
  const org = res.getOrganization()
  const position = res.getPosition()

  if (!name || !nameKana || !org || !position) {
    throw new Error('required user attributes seems blank')
  }

  const user: ActiveUser = {
    guid: res.getUserGuid(),
    email: res.getEmail(),
    name,
    nameKana,
  }

  const organization: Organization = {
    guid: org.getOrganizationGuid(),
    name: org.getOrganizationName(),
  }

  const belongingTeams: BelongingTeam[] = res
    .getTeamsList()
    .map((t: GetMyUserAttributesResponse.Team): BelongingTeam => {
      return {
        guid: t.getTeamGuid(),
        name: t.getTeamName(),
        role: fromProtoTeamBelongingRole(t.getRole()),
      }
    })

  return {
    user,
    organization,
    belongingTeams,
    isManager: position.getManager(),
    isAdmin: position.getAdministrator(),
  }
}
