import { CustomOrbiterGrpcError } from '@/services/rpcService/customGrpcError'
import type * as Sentry from '@sentry/react'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const sentryOptions: Sentry.BrowserOptions = {
  dsn:
    SENTRY_DSN ||
    'https://d52af1701c994bcfb713e84e884e1275@o248153.ingest.sentry.io/6391293',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
    // TODO: タイムアウト等sentryに通知不要なエラーは無視する
    if (hint && hint.originalException instanceof CustomOrbiterGrpcError) {
      if (!event.tags) {
        event.tags = {}
      }
      event.tags.grpc_status_code = hint.originalException.code.toString()
      event.tags.git_tag = process.env.NEXT_PUBLIC_GIT_TAG ?? '????'
      event.tags.git_hash = process.env.NEXT_PUBLIC_GIT_HASH ?? '???????'
      if (!event.extra) {
        event.extra = {}
      }
      event.extra.CustomGrpcError = hint.originalException
    }
    return event
  },
}
