import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

export const useAppRefresher = () => {
  const router = useRouter()

  const buildId = useMemo(() => {
    if (typeof window === 'undefined') return ''
    const nextData = document.querySelector('#__NEXT_DATA__')
    if (nextData?.textContent) {
      return JSON.parse(nextData.textContent).buildId
    }
    return ''
  }, [])

  const checkVersion = async () => {
    try {
      const res = await fetch(`/_next/static/${buildId}/_buildManifest.js`)
      return {
        newVersionAvailable: res.status === 404,
      }
    } catch {
      return {
        newVersionAvailable: false,
      }
    }
  }

  const { data } = useQuery({
    queryKey: ['next-build-id', buildId],
    queryFn: checkVersion,
    refetchInterval: 1000 * 60 * 60, // 1時間
    enabled: !!buildId,
  })

  useEffect(() => {
    const refreshHandler = () => {
      if (typeof window === 'undefined') return
      if (data?.newVersionAvailable) {
        window.location.reload()
      }
    }
    router.events.on('routeChangeComplete', refreshHandler)
    return () => {
      router.events.off('routeChangeComplete', refreshHandler)
    }
  }, [router.events, data?.newVersionAvailable])
}
