import { Icon } from '@chakra-ui/react'
import NextLink from 'next/link'
import { Link } from '@/components/common/Link'
import type { UrlObjectType } from '@/components/common/Link'
import type { ComlinkPushParams } from '@blue-agency/comlink/dist/src/app'
import type { LinkProps } from '@chakra-ui/react'
import type { IconType } from 'react-icons/lib'

type Props = {
  href: string | UrlObjectType
  comlinkPushParams: Omit<ComlinkPushParams, 'group'>
  isExternal?: boolean
  text: string
  leftIcon?: IconType
  rightIcon?: IconType
} & Omit<LinkProps, 'href'>

export const TextLink = ({
  href,
  comlinkPushParams,
  isExternal,
  text,
  leftIcon,
  rightIcon,
  ...props
}: Props) => {
  return (
    <Link
      as={NextLink}
      href={href}
      isExternal={isExternal}
      comlinkPushParams={comlinkPushParams}
      {...props}
      color='blue.500'
    >
      {leftIcon && (
        <Icon
          as={leftIcon}
          mx='0.5'
          verticalAlign='middle'
          title={isExternal ? '別窓で開くリンク' : undefined}
        />
      )}
      {text}
      {rightIcon && (
        <Icon
          as={rightIcon}
          mx='0.5'
          verticalAlign='middle'
          title={isExternal ? '別窓で開くリンク' : undefined}
        />
      )}
    </Link>
  )
}
