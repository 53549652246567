import { useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { useAuth } from '@/hooks/auth/useAuth'
import { LOCAL_STORAGE_KEY } from '@/services/storageService/localStorageKey'
import type { BelongingTeam } from '@/services/teamService/belongingTeam'

export const useCurrentTeam = () => {
  const { userAttributes } = useAuth()
  const [currentTeamGuid, setCurrentTeamGuid] = useLocalStorage(
    LOCAL_STORAGE_KEY.currentTeamGuid
  )

  const currentTeam = useMemo(() => {
    const belongingTeams = userAttributes?.belongingTeams
    if (!belongingTeams || belongingTeams.length === 0) return

    const currentTeam = belongingTeams.find(
      (team) => team.guid === currentTeamGuid
    )
    if (currentTeam) return currentTeam

    // 現在のチームが存在しない場合は最新のチームを現在のチームに設定する
    const latestTeam = getLatestTeam(belongingTeams)
    setCurrentTeamGuid(latestTeam.guid)
    return latestTeam
  }, [currentTeamGuid, setCurrentTeamGuid, userAttributes?.belongingTeams])

  return {
    currentTeam,
    setCurrentTeamGuid,
  }
}

const getLatestTeam = (teams: BelongingTeam[]): BelongingTeam => {
  teams = teams.sort((a, b) => {
    if (a.guid > b.guid) {
      return -1
    } else {
      return 1
    }
  })

  return teams[0]
}
