import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { MdPerson, MdPersonOutline } from 'react-icons/md'
import { SelectedTeam } from '@/components/dashboard/SelectedTeam'
import { useAuth } from '@/hooks/auth/useAuth'
import { useCurrentTeam } from '@/hooks/my/useCurrentTeam'
import { useIsPcSize } from '@/hooks/useIsPcSize'
import { getUserFullName } from '@/services/userService/getUserFullName'

export const AccountMenu = () => {
  const { userAttributes } = useAuth()
  const { currentTeam, setCurrentTeamGuid } = useCurrentTeam()
  const isPcSize = useIsPcSize()
  const userName = useMemo(
    () => (userAttributes ? getUserFullName(userAttributes.user) : ''),
    [userAttributes]
  )

  return (
    <Menu autoSelect={false} gutter={0}>
      <MenuButton
        aria-label='アカウントメニューを開く'
        w='9'
        h='9'
        m='auto'
        borderRadius='full'
        bgColor={isPcSize ? 'gray.400' : 'transparent'}
        _hover={{
          bgColor: isPcSize ? 'gray.500' : 'gray.100',
        }}
        _active={{
          bgColor: isPcSize ? 'gray.500' : 'gray.200',
        }}
      >
        <Flex alignItems='center' justifyContent='center'>
          {isPcSize ? (
            <MdPerson size='1.5rem' color='white' />
          ) : (
            <MdPersonOutline size='1.5rem' />
          )}
        </Flex>
      </MenuButton>
      <MenuList maxW='16rem'>
        <Box px='3' py='2'>
          <Text fontWeight='bold'>{userName}</Text>
          {currentTeam && userAttributes && (
            <SelectedTeam
              belongingTeams={userAttributes.belongingTeams}
              currentTeam={currentTeam}
              setCurrentTeamGuid={setCurrentTeamGuid}
            />
          )}
        </Box>
        <Divider />
        <MenuItem px='3' py='2' as='a' href='/auth/logout'>
          <Text colorScheme='teal' color='gray.500'>
            ログアウト
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
