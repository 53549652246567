import { Button, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { withComlinkPushOnClick } from '@/lib/withComlinkPushOnClick'
import type { Props as ButtonProps } from './PrimaryButton'

export const SecondaryButton = withComlinkPushOnClick(
  ({ children, isDisabled, ...props }: ButtonProps) => {
    if (props.href && !isDisabled) {
      return (
        <Link
          as={NextLink}
          href={props.href}
          _hover={{ textDecoration: 'none' }}
        >
          <Button
            as='span'
            minW='140px'
            variant='outline'
            colorScheme='teal'
            color='teal.400'
            size='md'
            {...props}
          >
            {children}
          </Button>
        </Link>
      )
    } else {
      return (
        <Button
          minW='140px'
          variant='outline'
          colorScheme='teal'
          color='teal.400'
          size='md'
          isDisabled={isDisabled}
          {...props}
        >
          {children}
        </Button>
      )
    }
  }
)
