import NextLink from 'next/link'
import { Link } from '@/components/common/Link'
import type { ComlinkPushParams } from '@blue-agency/comlink/dist/src/app'

type Props = {
  title: string
  path: string
  currentPath: string
  comlinkPushParams: Omit<ComlinkPushParams, 'group'>
}
export const HeaderItem = ({
  title,
  path,
  currentPath,
  comlinkPushParams,
}: Props) => {
  const isCurrent = currentPath.startsWith(path)

  return (
    <Link
      as={NextLink}
      comlinkPushParams={comlinkPushParams}
      href={path}
      display='inline-block'
      px='6'
      pt='1.4rem'
      fontSize='md'
      borderBottom='4px solid'
      borderBottomColor={isCurrent ? 'teal.300' : 'transparent'}
      _hover={{
        textDecoration: 'none',
        bgColor: 'gray.100',
      }}
    >
      {title}
    </Link>
  )
}
