import { Auth } from '@aws-amplify/auth'
import type { CognitoUser } from '@aws-amplify/auth'
import type {
  CognitoUserSession,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'

export type CurrentSession =
  | {
      valid: true
      guid: string
      email: string
      idToken: string
    }
  | {
      valid: false
      guid?: string
      email?: string
      idToken?: string
    }

export const getCurrentSession = async (): Promise<CurrentSession> => {
  if (
    process.env
      .NEXT_PUBLIC_STADIUM_INTERNAL_ORBITER_FRONT_ENABLE_PREVIEW_AUTHENTICATION ===
    'true'
  ) {
    const guid =
      localStorage.getItem(
        'stadium_internal_orbiter_front_preview_authentication_guid'
      ) ??
      process.env
        .NEXT_PUBLIC_STADIUM_INTERNAL_ORBITER_FRONT_PREVIEW_AUTHENTICATION_FALLBACK_GUID ??
      ''
    // eslint-disable-next-line no-console
    console.log(
      `PREVIEW AUTHENTICATION ENABLED: DOT NOT USE THIS MODE FOR PRODUCTION, guid=${guid}`
    )
    return {
      guid: guid,
      email: '',
      idToken: guid,
      valid: true,
    }
  }
  const sess: CognitoUserSession | null = await Auth.currentSession().catch(
    (e) => {
      console.error(e)
      return null
    }
  )

  if (!sess) {
    return {
      valid: false,
    }
  }

  const idToken = sess.getIdToken()
  const guid = idToken.payload['custom:guid']
  return {
    valid: sess.isValid() && !!guid,
    guid: guid as string,
    email: idToken.payload['email'] as string,
    idToken: idToken.getJwtToken(),
  }
}

export type IDTokenRefresher = () => Promise<void>

export const refreshIdToken = async () => {
  const user = await Auth.currentAuthenticatedUser()
  const refreshToken = user.getSignInUserSession()?.getRefreshToken()
  await cognitoUserRefreshSession(user, refreshToken)
}

const cognitoUserRefreshSession = (
  user: CognitoUser,
  token: CognitoRefreshToken
) => {
  return new Promise((resolve, reject) => {
    user.refreshSession(token, (err, result) => {
      if (err) reject(err)
      else resolve(result)
    })
  })
}
