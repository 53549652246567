import { forwardRef, Link as ChakraLink } from '@chakra-ui/react'
import React from 'react'
import { withComlinkPushOnClick } from '@/lib/withComlinkPushOnClick'
import { formatUrl } from '@/utils/formatUrl'
import type { PropsWithRefOf } from '@/lib/withComlinkPushOnClick'
import type { LinkProps } from '@chakra-ui/react'
import type { AnchorHTMLAttributes } from 'react'

type Props = PropsWithRefOf<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
> &
  LinkProps

const Component = withComlinkPushOnClick(({ children, ...props }: Props) => (
  <ChakraLink {...props}>{children}</ChakraLink>
))

/** Linkコンポーネントに使えるURL用の型 */
// NOTE: ＠typesとしてグローバルで管理する必要があれば切り離す
export type UrlObjectType = {
  pathname: string
  query: Record<string, string>
}

type CustomLinkProps = Omit<Props, 'href'> & {
  href: string | UrlObjectType
}
// next/link直下にカスタムコンポーネントを置くと、Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()? というエラーが出るので、forwardRefを使う
export const Link = forwardRef<CustomLinkProps, typeof Component>(
  ({ href, children, ...props }, _ref) => {
    // NOTE: formatUrlでUrlObjectだったリンクをstringに変換する
    const hrefString = href ? formatUrl(href) : undefined
    return (
      <Component href={hrefString} {...props}>
        {children}
      </Component>
    )
  }
)
