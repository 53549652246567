import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    red: {
      75: '#ffecec',
    },
  },
  styles: {
    global: {
      body: {
        color: 'gray.700',
        fontSize: 'sm',
      },
    },
  },
  components: {
    Divider: {
      baseStyle: {
        opacity: 1,
      },
    },
    Menu: {
      baseStyle: {
        divider: {
          opacity: 1,
        },
      },
    },
  },
  fonts: {
    heading: `'Noto Sans JP', sans-serif`,
    body: `'Noto Sans JP', sans-serif`,
  },
  breakpoints: {
    /**
     * NOTE: iPhone11以降のシェア率が高い機種で、Landscape時のデバイスピクセルを参照。
     */
    sm: '56rem', //=896px //NOTE: SPとPCの境目。
    md: '62rem', //=992px //NOTE: PCの広域・狭域の境目。PCで２カラムを並べる最小幅。
    lg: '80rem', //=1280px
    xl: '80rem', //=1280px
    '2xl': '80rem', //=1280px
  },
})
