import type { Name, NameKana } from '@/services/userService/user'
import type {
  Name as ProtoName,
  NameKana as ProtoNameKana,
} from '@blue-agency/proton/web/v2/name_data_pb'

export const fromProtoName = (name?: ProtoName): Name | undefined => {
  if (!name) return
  return {
    familyName: name.getFamilyName(),
    givenName: name.getGivenName(),
  }
}

export const fromProtoNameKana = (
  nameKana?: ProtoNameKana
): NameKana | undefined => {
  if (!nameKana) return
  return {
    familyNameKana: nameKana.getFamilyNameKana(),
    givenNameKana: nameKana.getGivenNameKana(),
  }
}
