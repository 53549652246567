import { Code } from '@blue-agency/proton/web/google/rpc/code_pb'
import { Status } from '@blue-agency/proton/web/google/rpc/status_pb'
import {
  BadRequest,
  ErrorInfo,
  PreconditionFailure,
} from '@blue-agency/proton/web/v2/errdetails/error_details_pb'
import ExtensibleCustomError from 'extensible-custom-error'
import type { ErrorReason } from '@blue-agency/proton/web/v2/orbiter/error_reason_pb'
import type * as jspb from 'google-protobuf'
import type * as google_protobuf_any_pb from 'google-protobuf/google/protobuf/any_pb'
import type * as grpcWeb from 'grpc-web'

type Reason = keyof typeof ErrorReason

export type GrpcError = {
  message: string
  code: number
  metadata: grpcWeb.Metadata
}

export class CustomGrpcError extends Error {
  code: number
  metadata: grpcWeb.Metadata

  constructor(err: GrpcError) {
    super(err.message)
    this.name = 'CustomGrpcError'
    this.code = err.code
    this.metadata = err.metadata
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }

  get errorDetails(): Array<google_protobuf_any_pb.Any> {
    const statusEncoded = this.metadata['grpc-status-details-bin']
    if (statusEncoded === undefined) return []

    const status = Status.deserializeBinary(
      statusEncoded as unknown as Uint8Array
    )
    return status.getDetailsList()
  }

  get errorDetailsOfGoogleApis(): Array<jspb.Message> {
    const statusEncoded = this.metadata['grpc-status-details-bin']
    if (statusEncoded === undefined) return []

    // 使うエラーは都度 追加する
    // 現状使うのは ErrorInfo だけだが他にも対応していることがわかりやすいように 'PreconditionFailure' も記述しておく
    const errorDetailNamespaces = {
      ErrorInfo: ErrorInfo,
      PreconditionFailure: PreconditionFailure,
      BadRequest: BadRequest,
    }
    type ErrorDetailName = keyof typeof errorDetailNamespaces

    const status = Status.deserializeBinary(
      statusEncoded as unknown as Uint8Array
    )
    return status.getDetailsList().map((d) => {
      const name = d.getTypeName()
      if (!name.startsWith('proton.')) {
        return d
      }

      const errorDetailName = name.split('.').pop() as ErrorDetailName
      return errorDetailNamespaces[errorDetailName].deserializeBinary(
        d.getValue_asU8()
      )
    })
  }

  get isUnauthenticated(): boolean {
    return this.code === Code.UNAUTHENTICATED
  }

  get isNotFound(): boolean {
    return this.code === Code.NOT_FOUND
  }

  get isAlreadyExists(): boolean {
    return this.code === Code.ALREADY_EXISTS
  }

  get isInvalidArgument(): boolean {
    return this.code === Code.INVALID_ARGUMENT
  }

  get isFailedPrecondition(): boolean {
    return this.code === Code.FAILED_PRECONDITION
  }

  get isResourceExhausted(): boolean {
    return this.code === Code.RESOURCE_EXHAUSTED
  }
}

export class CustomOrbiterGrpcError extends CustomGrpcError {
  /**
   * 特定のErrorDetailのReasonを持っているか確かめる
   * @param reason ErrorInfoで定義されたErrorReason
   * @returns 引数のreason === 実際のreason
   */
  hasMatchErrorDetail(reason: Reason): boolean {
    return this.errorDetailsOfGoogleApis.some((d) => {
      if (d instanceof ErrorInfo) {
        const r = d.getReason() as Reason
        return r === reason
      }
      return false
    })
  }

  /**
   * @param reason ErrorInfoで定義されたErrorReason
   * @returns 該当のErrorReasonのMetadata
   */
  getErrorDetailMetadataMap(
    reason: Reason
  ): jspb.Map<string, string> | undefined {
    let res: jspb.Map<string, string> | undefined = undefined
    this.errorDetailsOfGoogleApis.forEach((d) => {
      if (d instanceof ErrorInfo) {
        const r = d.getReason() as Reason
        if (r === reason) {
          res = d.getMetadataMap()
          return
        }
      }
    })
    return res
  }

  /**
   * @returns FieldViolationの一覧
   */
  getFieldViolations(): BadRequest.FieldViolation[] {
    let res: BadRequest.FieldViolation[] = []
    for (const d of this.errorDetailsOfGoogleApis) {
      if (d instanceof BadRequest) {
        res = d.getFieldViolationsList()
        break
      }
    }
    return res
  }
}

export const buildErrorByGrpcError = (error: GrpcError): Error => {
  if (error.code === Code.PERMISSION_DENIED) {
    return new PermissionDenied(error as unknown as Error)
  }
  return new CustomOrbiterGrpcError(error)
}

export class PermissionDenied extends ExtensibleCustomError {}
