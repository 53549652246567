import { Button } from '@chakra-ui/react'
import { withComlinkPushOnClick } from '@/lib/withComlinkPushOnClick'
import type { Props as ButtonProps } from './PrimaryButton'

export const DangerButton = withComlinkPushOnClick(
  ({ children, ...props }: ButtonProps) => (
    <Button minW='140px' colorScheme='red' size='md' {...props}>
      {children}
    </Button>
  )
)
