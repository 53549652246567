import { Flex, Text, VStack } from '@chakra-ui/react'
import { SwitchTeamButton } from '@/components/common/Button'
import type { BelongingTeam } from '@/services/teamService/belongingTeam'

type Props = {
  belongingTeams: BelongingTeam[]
  currentTeam: BelongingTeam
  setCurrentTeamGuid: (teamGuid: string) => void
}
export const SelectedTeam = ({
  belongingTeams,
  currentTeam,
  setCurrentTeamGuid,
}: Props) => {
  return (
    <VStack spacing='1' align='stretch'>
      <Flex alignItems='center' justifyContent='space-between' gap='2'>
        <Text fontSize='xs' color='gray.500'>
          選択中のチーム
        </Text>
        {belongingTeams.length >= 2 && (
          <SwitchTeamButton
            belongingTeams={belongingTeams}
            currentTeam={currentTeam}
            setCurrentTeamGuid={setCurrentTeamGuid}
          />
        )}
      </Flex>
      <Text>{currentTeam.name}</Text>
    </VStack>
  )
}
