import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Link } from '@/components/common/Link'
import { useAuth } from '@/hooks/auth/useAuth'
import { useCurrentTeam } from '@/hooks/my/useCurrentTeam'
import { ACTIONS } from '@/lib/comlinkKey'
import { AccountMenu } from './AccountMenu'
import { HeaderItem } from './HeaderItem'
import { Logo } from './Logo'
import type { BelongingTeam } from '@/services/teamService/belongingTeam'

export const PcHeaderNavi = () => {
  const { userAttributes } = useAuth()
  const { currentTeam } = useCurrentTeam()
  const router = useRouter()

  const isLeader = useMemo(() => {
    return currentTeam?.role === 'leader'
  }, [currentTeam?.role])

  const isManager = useMemo(() => {
    return !!userAttributes?.isManager
  }, [userAttributes?.isManager])

  const isAdmin = useMemo(() => {
    return !!userAttributes?.isAdmin
  }, [userAttributes?.isAdmin])

  const currentPath = router.pathname

  return (
    <>
      <Flex gap='5'>
        <Logo />
        <Flex>
          <HeaderItem
            title='ダッシュボード'
            path='/dashboard'
            currentPath={currentPath}
            comlinkPushParams={{ action: ACTIONS.MOVE_TO.DASHBOARD }}
          />
          {currentTeam && (
            <HeaderItem
              title='ロールプレイング'
              path='/my-scenes'
              currentPath={currentPath}
              comlinkPushParams={{ action: ACTIONS.MOVE_TO.MY_SCENES }}
            />
          )}
          {userAttributes && (isManager || currentTeam) && (
            <ReportsHeaderItem
              userGuid={userAttributes.user.guid}
              isManager={isManager}
              currentTeam={currentTeam}
              currentPath={currentPath}
            />
          )}
          {(isAdmin || isLeader) && (
            <ManagementHeaderItem
              isAdmin={isAdmin}
              isLeader={isLeader}
              currentPath={currentPath}
            />
          )}
        </Flex>
      </Flex>
      <Spacer />
      <AccountMenu />
    </>
  )
}

const ReportsHeaderItem = ({
  userGuid,
  isManager,
  currentTeam,
  currentPath,
}: {
  userGuid: string
  isManager: boolean
  currentTeam: BelongingTeam | undefined
  currentPath: string
}) => {
  const isCurrent = useMemo(() => {
    return (
      currentPath === '/manager-report' ||
      currentPath === '/team-summary' ||
      currentPath === '/user-report'
    )
  }, [currentPath])
  /**
      Memo:
      レポート系が出てくる条件は、マネージャー またはチームに所属している
      レポートの項目が複数になる条件は、チームに属しているとき
      レポートの項目が複数にならない条件は、マネージャー かつ !チームに属しているとき
      つまり、マネージャーまたはチームに属している条件下で、チームに属しているかどうかで分岐
   */
  if (!currentTeam)
    return (
      <HeaderItem
        title='レポート'
        path='/manager-report'
        currentPath={currentPath}
        comlinkPushParams={{ action: ACTIONS.MOVE_TO.MANAGER_REPORT }}
      />
    )

  return (
    <Menu autoSelect={false} gutter={0}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            rightIcon={
              isOpen ? (
                <MdKeyboardArrowUp size='1.5rem' />
              ) : (
                <MdKeyboardArrowDown size='1.5rem' />
              )
            }
            m='auto'
            px='6'
            pt='1.4rem'
            pb='1.2rem'
            h='full'
            bgColor='transparent'
            borderRadius='none'
            borderBottom='4px solid'
            borderBottomColor={isCurrent ? 'teal.300' : 'transparent'}
            fontWeight='normal'
            fontSize='md'
            _hover={{
              bgColor: 'gray.100',
            }}
            _active={{
              bgColor: 'gray.200',
            }}
          >
            レポート
          </MenuButton>
          <MenuList minW='9rem' px='0.0625rem' py='2'>
            {isManager && (
              <Link
                as={NextLink}
                comlinkPushParams={{
                  action: ACTIONS.MOVE_TO.MANAGER_REPORT,
                }}
                href='/manager-report'
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Text
                  py='2'
                  px='3'
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                >
                  グループレポート
                </Text>
              </Link>
            )}
            <Link
              as={NextLink}
              comlinkPushParams={{ action: ACTIONS.MOVE_TO.TEAM_SUMMARY }}
              href={'/team-summary?teamGuid=' + currentTeam.guid}
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Text
                py='2'
                px='3'
                _hover={{
                  bgColor: 'gray.100',
                }}
              >
                チームレポート
              </Text>
            </Link>
            <Link
              as={NextLink}
              comlinkPushParams={{ action: ACTIONS.MOVE_TO.USER_REPORT }}
              href={
                '/user-report?userGuid=' +
                userGuid +
                '&teamGuid=' +
                currentTeam.guid
              }
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Text
                py='2'
                px='3'
                _hover={{
                  bgColor: 'gray.100',
                }}
              >
                ユーザーレポート
              </Text>
            </Link>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

const ManagementHeaderItem = ({
  isAdmin,
  isLeader,
  currentPath,
}: {
  isAdmin: boolean
  isLeader: boolean
  currentPath: string
}) => {
  const isCurrent = useMemo(() => {
    return (
      currentPath.startsWith('/admin/') ||
      currentPath === '/scenes' ||
      currentPath.startsWith('/scenes/') ||
      currentPath === '/team-users'
    )
  }, [currentPath])
  /** Memo:
      管理系が出てくる条件は、管理者 or リーダー
      管理の項目が単数になる条件は 管理者 かつ リーダーではない または !管理者 かつリーダー
      管理の項目が単数にならない条件は、管理者 かつ リーダー
      つまり、管理者 or リーダーの条件で、管理者かつリーダーの場合で処理
   */
  if (isAdmin && isLeader)
    return (
      <Menu autoSelect={false} gutter={0}>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              rightIcon={
                isOpen ? (
                  <MdKeyboardArrowUp size='1.5rem' />
                ) : (
                  <MdKeyboardArrowDown size='1.5rem' />
                )
              }
              m='auto'
              px='6'
              pt='1.4rem'
              pb='1.2rem'
              h='full'
              bgColor='transparent'
              borderRadius='none'
              borderBottom='4px solid'
              borderBottomColor={isCurrent ? 'teal.300' : 'transparent'}
              fontSize='md'
              fontWeight='normal'
              _hover={{
                bgColor: 'gray.100',
              }}
              _active={{
                bgColor: 'gray.200',
              }}
            >
              <Text>管理</Text>
            </MenuButton>
            <MenuList minW='7rem' px='0.0625rem' py='2'>
              <Link
                as={NextLink}
                comlinkPushParams={{
                  action: ACTIONS.MOVE_TO.ADMIN_USERS,
                }}
                href='/admin/users'
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Text
                  py='2'
                  px='3'
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                >
                  組織管理
                </Text>
              </Link>
              <Link
                as={NextLink}
                comlinkPushParams={{ action: ACTIONS.MOVE_TO.SCENES }}
                href='/scenes'
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Text
                  py='2'
                  px='3'
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                >
                  チーム管理
                </Text>
              </Link>
            </MenuList>
          </>
        )}
      </Menu>
    )

  if (isLeader)
    return (
      <HeaderItem
        title='管理'
        path='/scenes'
        currentPath={currentPath}
        comlinkPushParams={{ action: ACTIONS.MOVE_TO.SCENES }}
      />
    )

  return (
    <HeaderItem
      title='管理'
      path='/admin/users'
      currentPath={currentPath}
      comlinkPushParams={{ action: ACTIONS.MOVE_TO.ADMIN_USERS }}
    />
  )
}
