import { Flex } from '@chakra-ui/react'
import { useIsPcSize } from '@/hooks/useIsPcSize'
import { PC_HEADER_HEIGHT, SP_HEADER_HEIGHT } from '.'
import { Logo } from './Logo'
import { PcHeaderNavi } from './PcHeaderNavi'
import { SpHeaderNavi } from './SpHeaderNavi'

type Props = {
  isUserLogin: boolean
}
export const Header = ({ isUserLogin }: Props) => {
  const isPcSize = useIsPcSize()

  return (
    <Flex
      as='header'
      h={isPcSize ? PC_HEADER_HEIGHT : SP_HEADER_HEIGHT}
      pl={{ base: '4', sm: '6' }}
      pr={{ base: '2', sm: '4' }}
      flex='0 0 auto'
    >
      {!isUserLogin ? <Logo /> : isPcSize ? <PcHeaderNavi /> : <SpHeaderNavi />}
    </Flex>
  )
}
