import {
  Center,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { ACTIONS } from '@/lib/comlinkKey'
import { getRoleName } from '@/services/userService/getRoleName'
import { PrimaryButton } from './PrimaryButton'
import { TertiaryButton } from './TertiaryButton'
import type { BelongingTeam } from '@/services/teamService/belongingTeam'

type SwitchTeamButtonProps = {
  belongingTeams: BelongingTeam[]
  currentTeam: BelongingTeam
  setCurrentTeamGuid: (teamGuid: string) => void
}

export const SwitchTeamButton = ({
  belongingTeams,
  currentTeam,
  setCurrentTeamGuid,
}: SwitchTeamButtonProps) => {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTeamGuid, setSelectedTeamGuid] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const onSwitchClick = useCallback(() => {
    if (!selectedTeamGuid) return

    setIsLoading(true)
    setCurrentTeamGuid(selectedTeamGuid)
    router.push('/')
  }, [router, selectedTeamGuid, setCurrentTeamGuid])

  return (
    <>
      <TertiaryButton
        size='xs'
        onClick={onOpen}
        minW='auto'
        comlinkPushParams={{ action: ACTIONS.OPEN.SWITCH_TEAM_MODAL }}
      >
        チーム切替
      </TertiaryButton>
      {isOpen && (
        <Modal isOpen onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent w='430px'>
            <ModalHeader>チーム切替</ModalHeader>
            <ModalBody>
              <FormLabel>現在選択中のチーム</FormLabel>
              <Text fontSize='md'>{currentTeam.name}</Text>
              <FormControl mt='16px'>
                <FormLabel>切替可能なチーム</FormLabel>
                <Select
                  onChange={(e) => setSelectedTeamGuid(e.target.value)}
                  placeholder='選択してください'
                  value={selectedTeamGuid}
                >
                  {belongingTeams
                    .filter((team) => team.guid !== currentTeam.guid)
                    .map((team) => (
                      <option key={team.guid} value={team.guid}>
                        {team.name} （{getRoleName(team.role)}）
                      </option>
                    ))}
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter justifyContent='center'>
              <Center w='308px'>
                <TertiaryButton
                  mr={3}
                  onClick={onClose}
                  isDisabled={isLoading}
                  comlinkPushParams={{
                    action: ACTIONS.CLOSE.SWITCH_TEAM_MODAL,
                  }}
                >
                  キャンセル
                </TertiaryButton>
                <Spacer />
                <PrimaryButton
                  onClick={onSwitchClick}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  comlinkPushParams={{
                    action: ACTIONS.SWITCH.TEAM,
                    targetIdStr: selectedTeamGuid,
                  }}
                >
                  切り替える
                </PrimaryButton>
              </Center>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
