import { assertNever } from '@/utils/assertNever'
import type { Role } from './user'

export const getRoleName = (role: Role): string => {
  switch (role) {
    case 'member':
      return 'メンバー'
    case 'leader':
      return 'リーダー'
    default:
      assertNever(role)
  }
}
